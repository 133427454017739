import { render, staticRenderFns } from "./BookingRoom.vue?vue&type=template&id=678d80b8&"
import script from "./BookingRoom.vue?vue&type=script&lang=js&"
export * from "./BookingRoom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sherwoodqueenstownnz/sherwoodqueenstown.nz/releases/20250306024512/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('678d80b8')) {
      api.createRecord('678d80b8', component.options)
    } else {
      api.reload('678d80b8', component.options)
    }
    module.hot.accept("./BookingRoom.vue?vue&type=template&id=678d80b8&", function () {
      api.rerender('678d80b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/BookingRoom.vue"
export default component.exports